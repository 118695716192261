import * as React from "react"

// markup
const NotFoundPage = () => {

  if (typeof window !== 'undefined') {
    window.location = '/';
  }

  return (
    <main>
      Not Found
    </main>
  )
}

export default NotFoundPage
